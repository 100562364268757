export default class Posm {
  /**
   * @param {string} name
   * @param {string} data
   * @param {number} [id]
   */
  constructor(name, data, id) {
    this.name = name;
    this.data = data;
    this.id = id;
  }
}
