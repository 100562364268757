export default class PosmCategory {
  /**
   * @param {string} name
   * @param {*} inputType
   * @param {*} posms
   */
  constructor(name, inputType, posms) {
    this.name = name;
    this.inputType = inputType;
    this.posms = posms;
  }
}
