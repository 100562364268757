<template>
  <KTCodePreview v-bind:title="'Kết quả báo cáo POSM'">
    <template v-slot:preview>
      <div>
        <b-card
          :title="
            `Cửa hàng: ${submissionInfo.store.name} (${submissionInfo.store.shortName})`
          "
          class="card-custom"
        >
          <b-row class="mb-6">
            <b-col cols="6">
              <ul>
                <b-card-text
                  >Tên chiến dịch:
                  {{ submissionInfo.campaign.name }}
                </b-card-text>
                <b-card-text
                  >Ngày bắt đầu: {{ formattedStartDate }}
                </b-card-text>
                <b-card-text>Ngày kết thúc: {{ formattedEndDate }}</b-card-text>
              </ul>
            </b-col>
            <b-col cols="6">
              <b-card-text
                >Tại địa chỉ: {{ submissionInfo.store.address }}
              </b-card-text>
              <b-card-text
                >Người khởi tạo Form:
                {{ submissionInfo.employeeName }}
              </b-card-text>
              <b-card-text
                >Điền vào lúc:
                {{ formattedPosmFormSubmissionDate }}
              </b-card-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-card-text><h3>Bên ngoài:</h3></b-card-text>
            </b-col>
          </b-row>
          <div
            v-for="(exteriorCategory, exteriorCategoryIndex) of submissionInfo
              .posmCategories.exteriorCategories"
            v-bind:key="
              `exterior-categories-${exteriorCategory.name}-${exteriorCategoryIndex}`
            "
            v-show="dataReceived"
          >
            <b-card-text>
              <h4>Hạng mục: {{ exteriorCategory.name }}</h4>
            </b-card-text>
            <b-row class="mb-6">
              <b-col
                cols="6"
                v-for="(posm, posmIndex) of exteriorCategory.posms"
                v-bind:key="
                  `exterior-category-${exteriorCategory.name}-${exteriorCategoryIndex}-${posm.name}-${posmIndex}`
                "
                class="mb-8"
              >
                <b-card
                  v-if="
                    ALLOWED_IMAGE_EXTENSIONS.some((token) =>
                      posm.data.includes(token),
                    )
                  "
                  class="card card-custom shadow-lg"
                  overlay
                  :img-src="posm.data"
                  text-variant="white"
                  bg-variant="dark"
                >
                  <b-card-text
                    class="text-right bg-dark d-inline-block rounded px-4 py-2"
                    style="opacity: 0.8"
                  >
                    <h5 class="mb-0">{{ posm.name }}</h5>
                  </b-card-text>
                </b-card>
                <div v-else>
                  <h5>{{ posm.name }}</h5>
                  <p>Hình chưa được điền, hoặc kiểu loại dữ liệu không phù hợp.</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col cols="12">
              <b-card-text><h3>Bên trong:</h3></b-card-text>
            </b-col>
          </b-row>
          <div
            v-for="(interiorCategory, interiorCategoryIndex) of submissionInfo
              .posmCategories.interiorCategories"
            v-bind:key="
              `interior-categories-${interiorCategory.name}-${interiorCategoryIndex}`
            "
            v-show="dataReceived"
          >
            <b-card-text>
              <h4>{{ interiorCategory.name }}</h4>
            </b-card-text>
            <b-row class="mb-4">
              <b-col
                cols="6"
                v-for="(posm, posmIndex) of interiorCategory.posms"
                v-bind:key="
                  `interior-category-${interiorCategory.name}-${interiorCategoryIndex}-${posm.name}-${posmIndex}`
                "
                class="mb-8"
              >
                <b-card
                  v-if="
                     ALLOWED_IMAGE_EXTENSIONS.some((token) =>
                      posm.data.includes(token),
                    )
                  "
                  class="card card-custom d-flex flex-row-reverse"
                  overlay
                  :img-src="posm.data"
                  text-variant="white"
                  bg-variant="dark"
                >
                  <div class="text-right d-inline-block">
                    <b-card-text
                      class="text-right bg-dark d-inline-block rounded px-4 py-2"
                      style="opacity: 0.8"
                    >
                      <h5 class="mb-0">{{ posm.name }}</h5>
                    </b-card-text>
                  </div>
                </b-card>
                <div v-else>
                  <h5>{{ posm.name }}</h5>
                  <p>Hình chưa được điền</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import ApiService from '@/core/services/api.service';
import { formatDate, makeToastFaile } from '@/utils/common';
import { cmdUrl } from '@/utils/apiUrl';
import { ALLOWED_IMAGE_EXTENSIONS, DATETIME_FORMAT, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import Campaign from '@/view/pages/posm/submission/models/Campaign';
import Store from '@/view/pages/posm/submission/models/Store';
import PosmFormInfo from '@/view/pages/posm/submission/models/PosmFormInfo';
import PosmCategory from '@/view/pages/posm/submission/models/PosmCategory';
import PosmCategories from '@/view/pages/posm/submission/models/PosmCategories';
import Posm from '@/view/pages/posm/submission/models/Posm';
import { POSM_GENERAL_LOCATIONS } from '@/utils/enum';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

function extractCategoriesAndDesignatedPosms(objectKey, data) {
  this.submissionInfo.posmCategories[objectKey] = Array.from(
    new Set(
      data.data.posmSubmissionItems
        .filter(
          (item) =>
            item.Posm.isExterior ===
            (objectKey === 'exteriorCategories' ? 1 : 0),
        )
        .map((item) => {
          return JSON.stringify(
            new PosmCategory(
              item.Posm.PosmCategory.name,
              item.Posm.PosmCategory.inputType,
            ),
          );
        }),
    ),
    (category) => JSON.parse(category),
  ).map((category) => {
    return new PosmCategory(
      category.name,
      category.inputType,
      data.data.posmSubmissionItems
        .filter((item) => item.Posm.PosmCategory.name === category.name)
        .map((item) => {
          return new Posm(item.Posm.name, item.data, item.Posm.id);
        })
        .sort((a, b) => a.id - b.id),
    );
  });
}

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      ALLOWED_IMAGE_EXTENSIONS,
      DATETIME_FORMAT,
      dataReceived: false,
      submissionInfo: new PosmFormInfo(
        new Campaign('Tên chiến dịch', '2020-01-01', '2020-01-01'),
        'Người điền form',
        new Store('Tên cửa hàng', 'Tên viết tắt', 'Địa chỉ'),
        'Ngày điền form',
        new PosmCategories(
          [
            new PosmCategory(
              'Tên danh mục bên ngoài 1',
              'Kiểu dữ liệu cho danh mục bên ngoài 1',
              [
                new Posm('Tên Posm 1 cho danh mục bên ngoài 1', ''),
                new Posm('Tên Posm 2 cho danh mục bên ngoài 1', ''),
              ],
            ),
            new PosmCategory(
              'Tên danh mục bên ngoài 2',
              'Kiểu dữ liệu cho danh mục bên ngoài 2',
              [
                new Posm('Tên Posm 1 cho danh mục bên ngoài 2', ''),
                new Posm('Tên Posm 2 cho danh mục bên ngoài 2', ''),
              ],
            ),
          ],
          [
            new PosmCategory(
              'Tên danh mục bên trong 1',
              'Kiểu dữ liệu cho danh mục bên trong 1',
              [
                new Posm('Tên Posm 1 cho danh mục bên trong 1', ''),
                new Posm('Tên Posm 2 cho danh mục bên trong 1', ''),
              ],
            ),
            new PosmCategory(
              'Tên danh mục bên trong 2',
              'Kiểu dữ liệu cho danh mục bên trong 2',
              [
                new Posm('Tên Posm 1 cho danh mục bên trong 2', ''),
                new Posm('Tên Posm 2 cho danh mục bên trong 2', ''),
              ],
            ),
          ],
        ),
      ),
    };
  },
  methods: {
    formatDate,
    fetchPosmFormSubmissionInfo(uuid) {
      ApiService.get(`${cmdUrl.PosmFormSubmission.root}/${uuid}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.submissionInfo.campaign = new Campaign(
              data.data.campaign.name,
              data.data.campaign.startDate,
              data.data.campaign.endDate,
            );
            this.submissionInfo.store = new Store(
              data.data.store.name,
              data.data.store.shortName,
              data.data.store.address,
            );
            this.submissionInfo.createdAt = data.data.createdAt;
            this.submissionInfo.employeeName = data.data.employeeName;
            extractCategoriesAndDesignatedPosms.call(
              this,
              POSM_GENERAL_LOCATIONS.EXTERIOR_CATEGORIES,
              data,
            );
            extractCategoriesAndDesignatedPosms.call(
              this,
              POSM_GENERAL_LOCATIONS.INTERIOR_CATEGORIES,
              data,
            );
            this.dataReceived = true;
          }
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        });
    },
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: 'Danh sách các Form POSM đã điền',
          route: '/posm/submissions',
        },
        { title: 'Kết quả báo cáo POSM' },
      ]);
      this.fetchPosmFormSubmissionInfo(this.$route.params.uuid);
    }
  },
  computed: {
    formattedStartDate() {
      return formatDate(
        new Date(this.submissionInfo.campaign.startDate),
        DATETIME_FORMAT,
      );
    },
    formattedEndDate() {
      return formatDate(
        new Date(this.submissionInfo.campaign.endDate),
        DATETIME_FORMAT,
      );
    },
    formattedPosmFormSubmissionDate() {
      return formatDate(
        new Date(this.submissionInfo.createdAt),
        DATETIME_FORMAT,
      );
    },
  },
};
</script>
